import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { getEnrollmentScheduledPayments, getUserProfile } from 'src/api';
import { getResourceLinks } from 'src/api/campus';
import authTokens from 'src/auth/auth-tokens';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { TokenHelper } from 'src/helpers/token-helper';
import routemap, { ADMIN } from 'src/routes/routemap';
import QueryKeys from 'src/types/query-keys';
import AccountSettingsMenu from '../account-settings-menu';
import BreadCrumbs from '../breadcrumbs';
import Header from '../header';
import Logo from '../logo';
import NotificationsDrawer from '../notifications-drawer';
import ResourceDrawer from '../resource-drawer';
import HelpFooter from '../footers/help-footer';
import LoadingComponent from '../loading-component';
import { LoadingStateFailedModal, GenericErrorModal } from '../error-modals';
import { ResourceLink } from 'src/types/resource-link';
import { Box, Container, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Icon } from '@pennfoster/pfc-design-system';
import { calcCreditPercentage } from 'src/helpers/credit-percentage';
import { getProgramName } from 'src/helpers/get-program-name';
import { isImpersonatingAtom } from 'src/state';
import { useAtom } from 'jotai';
import feEvents from 'src/events/fe-events';
import session from 'src/state/session';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  disableBackButton?: boolean;
  showHelpFooter?: boolean;
  hidden?: boolean;
}

export default function AppLayout({ children, backgroundColor, showHelpFooter = true, hidden = false }: Props) {
  const navigate = useNavigate();
  const tokenHelper = new TokenHelper(authTokens.getAccessToken());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdmin = tokenHelper.isAdmin();
  const bgColor = backgroundColor ?? theme.palette.background.default;
  const {
    user,
    queryStatus: userQueryStatus,
    isSwitchingEnrollments,
  } = useContext(UserContext);
  const [isImpersonating] = useAtom(isImpersonatingAtom);
  const { enrollment, queryStatus: enrollmentQueryStatus } =
    useContext(EnrollmentContext);

  if (isAdmin && !isImpersonating) {
    return <Navigate to={ADMIN} state={{ from: useLocation() }} />;
  }

  const [isPendoInitialized, setIsPendoInitialized] = useState(false);

  const { data: profileQueryData, status: profileQueryStatus } = useQuery(QueryKeys.GET_PROFILE, getUserProfile, {
    onError: (error) => console.error('Error: ', error),
    enabled: !isAdmin || (isAdmin && isImpersonating),
  });

  const { data: enrollmentScheduledPaymentsData, status: enrollmentScheduledPaymentsStatus } = useQuery(
    [QueryKeys.GET_ENROLLMENT_SCHEDULED_PAYMENTS, enrollment?.enrollmentId],
    () => {
      if (enrollment?.enrollmentId) {
        return getEnrollmentScheduledPayments(enrollment?.enrollmentId);
      }
    },
    {
      onError: (error) => console.error(error),
      enabled: !!enrollment?.enrollmentId,
    }
  );
  useEffect(() => {
    if (feEvents.isConnected) {
      window.addEventListener('click', (e: MouseEvent) => feEvents.handleClickStreamEvent(e));
      window.addEventListener('click', () => session.checkLastActivity());
    }
  }, [])
  // Initialize Pendo with User info
  useEffect(() => {
    if (
      isPendoInitialized ||
      userQueryStatus !== 'success' ||
      profileQueryStatus !== 'success' ||
      enrollmentQueryStatus !== 'success' ||
      enrollmentScheduledPaymentsStatus !== 'success'
    ) {
      return;
    }
    if (!user || !enrollment?.program) {
      console.warn('Enrollment & User queries were successful but data is missing');
      return;
    }

    const visitor = {
      id: user.studentNumber,
      LC_id: user.studentNumber,
      LC_email: user.email,
      LC_first_name: user.firstName,
      LC_is_migrated: user.isMigrated,
      LC_collection_status: user.studentCollectionStatus?.name,
      LC_mailing_state: profileQueryData?.data.address.regionAbbr,
      LC_mailing_country: profileQueryData?.data.address.country,
      LC_currentTerm: enrollment.currentTerm,
      LC_campus_code: enrollment.campusCode,
      LC_expiration_date: enrollment.expirationDate,
      LC_enrollment_date: enrollment.enrollmentDate,
      LC_program_display_name: getProgramName(enrollment),
      LC_degree_name: enrollment.degree.name,
      LC_academic_status_name: enrollment.academicStatus.name,
      LC_account_status: enrollment.accountStatus.name,
      LC_enrollment_credit_progress: calcCreditPercentage(enrollment.creditEarned, enrollment.creditRequired),
      LC_ledger_balance: enrollmentScheduledPaymentsData?.data.total,
      LC_program_type: enrollment.program.programType,
      LC_campus_name: enrollment.campusName,
      LC_partner_account_code: enrollment.partnerAccountCode,
      LC_dateofbirth : profileQueryData?.data.dateOfBirth,
      LC_eligible_to_reenroll : enrollment.eligibleToReEnroll,
      LC_preferred_name : user.preferredName
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pendo?.initialize({
      visitor,
    });
    feEvents.trackPendoEvent({
      eventName: 'PendoInitialization',
      dataSource: visitor,
    })
    setIsPendoInitialized(true);
  }, [userQueryStatus, profileQueryStatus, enrollmentQueryStatus, enrollmentScheduledPaymentsStatus]);

  const campusId = enrollment?.campusId || '';
  const resourceQuery = useQuery([QueryKeys.GET_RESOURCE_LINKS], () => getResourceLinks(campusId), {
    onError: (error) => console.error(error),
  });

  const extractResourceLink = (data: ResourceLink[] | undefined, type: string) => {
    if (Array.isArray(data)) {
      const foundLink = data.find((item) => item.resourceLinkType?.type.toLowerCase() === type.toLowerCase());
      return foundLink?.link || '';
    }
    return '';
  };

  const helpCenterLink = useMemo(
    () => extractResourceLink(resourceQuery.data?.data, 'help_center'),
    [resourceQuery.data]
  );

  const supportCaseLink = useMemo(
    () => extractResourceLink(resourceQuery.data?.data, 'support_case'),
    [resourceQuery.data]
  );

  if (
    userQueryStatus === 'loading' ||
    enrollmentQueryStatus === 'loading' ||
    profileQueryStatus === 'loading' ||
    isSwitchingEnrollments
  ) {
    return <LoadingComponent label="Loading" />;
  }

  if (userQueryStatus === 'error' || enrollmentQueryStatus === 'error' || profileQueryStatus === 'error') {
    return <LoadingStateFailedModal />;
  }

  const profile = profileQueryData?.data;

  if (hidden) {
    return <>{children}</>
  }

  if (!user || !profile || !enrollment) {
    return <GenericErrorModal />;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: bgColor,
          pb: 10,
          position: 'relative',
          minHeight: '100vh',
        }}
      >
        <Header
          sx={{
            position: 'sticky',
          }}
        >
          <Box key={'header-left-components'} sx={{ display: 'flex', height: '100%', width: '100%' }}>
            <Logo key="logo" />
          </Box>

          <Box key={'header-right-components'} sx={{ display: 'flex', alignItems: 'center' }}>
            {tokenHelper.isAdmin() && (
              <IconButton
                onClick={() => {
                  navigate(routemap.ADMIN);
                }}
              >
                <Icon.RemoveRedEye />
              </IconButton>
            )}
            {!isMobile ? (
              <>
                <ResourceDrawer />
                <Box>
                  <NotificationsDrawer key="notifications" />
                </Box>
              </>
            ) : null}
            <AccountSettingsMenu
              isMobile={isMobile}
              {...profile}
            />
          </Box>
        </Header>
        <Container component="main" sx={{
          marginTop: theme.spacing(5),
          [theme.breakpoints.up('sm')]: { pl: 3, pr: 3, },
          [theme.breakpoints.up('lg')]: { maxWidth: 1280, }
        }} className="app-layout-container">
          <BreadCrumbs />
          {children}
        </Container>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            textAlign: 'center',
            pb: 4,
          }}
        >
          {showHelpFooter && <HelpFooter helpCenterLink={helpCenterLink} supportCaseLink={supportCaseLink} />}
        </Box>
      </Box>
    </>
  );
}
